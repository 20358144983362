/* stylelint-disable css-modules/no-global-scoped-selector */
@import './responsive';
@import './commonStyling';
@import './toastifyOverride';
@import './reset';

.globalErrorBorder {
  border: 1px solid $errorMiddle !important;
  color: $errorMiddle;
  * {
    color: $errorMiddle !important;
  }
}
.globalValidBorder {
  border: 1px solid $successMiddle !important;
}

html {
  -webkit-text-size-adjust: 100%;
}

body {
  color: $fontsPrimary;
  @extend .fontPrimaryNormal;
}

button,
a {
  cursor: pointer;
  @extend .fontPrimaryNormal;
  border: none;
  background: transparent;
  text-decoration: none;
  color: initial;
  &:active,
  &:focus {
  }
}

* {
  --scrollbar-color-thumb: #c7c7c7;
  --scrollbar-color-track: #ffffff;
  --scrollbar-width-legacy: 6px;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7d7d7d;
}

.apexcharts-menu-item.exportSVG {
  display: none;
}
.apexcharts-xaxis-label {
  font-weight: 500 !important;
}
.apexcharts-tooltip {
  padding: 2px 6px;
  background-color: #314157 !important;
  color: white !important;
  border-radius: 8px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 140% !important;
  margin-top: 10px !important;
}
.apexcharts-pan-icon {
  display: none;
}
.apexcharts-reset-icon {
  margin-left: 0 !important;
}
.apexcharts-menu {
  box-shadow: 0 2px 16px 0 rgba(80, 117, 177, 0.10);
  border-radius: 8px !important;
  padding: 0 !important;
  right: 5px !important;
  border: 0 !important;
  min-width: 113px !important;
}
.apexcharts-menu-item {
  padding: 8px !important;
  align-items: center;
  font-family: "Proxima Nova", sans-serif;
  font-size: 14px !important;
  font-weight: 500;
  line-height: 140%;
  color: $fontsPrimary;
  &:hover {
    background: $grayscale100;
    border-radius: 8px 8px 0 0;
    &:last-child {
      border-radius: 0 0 8px 8px;
    }
  }
}
.apexcharts-toolbar .material-icons.disabled {
  //position: relative;
}

.apexcharts-toolbar .material-icons.disabled::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 10;
  pointer-events: none;
}