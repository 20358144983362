@import 'src/styles/responsive';
@import 'src/styles/commonStyling';

.ocrAudio{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  // min-height: 100vh;
  max-width: 250px;
  margin: auto;
  .recordButton {
    width: 100%;
  }
  .btnWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px -16px;
    & > button {
      margin: 0 8px;
    }
  }
}