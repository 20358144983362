@import 'src/styles/commonStyling.scss';

.heading {
  margin: 0;
}

.common {
  font-family: 'ProximaNova', sans-serif;
  letter-spacing: 0;
  line-height: auto;
}

// colors
.light {
  color: $grayscale500;
}

.normal {
  color: $grayscale700;
}

.dark {
  color: $grayscale900;
}

// sizes
.h1 {
  @extend .common;
  font-size: 32px;
  font-weight: bold;
}

.h2 {
  @extend .common;
  font-size: 28px;
  font-weight: bold;
}

.h3 {
  @extend .common;
  font-size: 24px;
  font-weight: bold;
}

.h4 {
  @extend .common;
  font-size: 20px;
  font-weight: bold;
}

.h5 {
  @extend .common;
  font-size: 18px;
  font-weight: bold;
}

.h6 {
  @extend .common;
  font-size: 16px;
  font-weight: 600; // semi-bold
}
