@import 'src/styles/commonStyling.scss';

.text {
  margin: 0;
}

.common {
  font-family: 'ProximaNova', sans-serif;
  line-height: auto;
  letter-spacing: 0;
}

// colors
.light {
  color: $grayscale500;
}

.normal {
  color: $grayscale700;
}

.dark {
  color: $grayscale900;
}

// Font weight classes
.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

// Size variants
.large {
  @extend .common;
  font-size: 16px;
}

.normal {
  @extend .common;
  font-size: 14px;
}

.small {
  @extend .common;
  font-size: 12px;
}
